<template>
    <div class='all_white save_desk'>
        <div class="save_desk_title">
            <h1>保存此网页至手机桌面方法</h1>
            <p>下次桌面图标点开即可使用</p>
        </div>
        <ul class="save_desk_items">
            <li @click="currentIndex = index" :class="{'active': index == currentIndex}" v-for="(item, index) in titles" :key="index">
                {{item}}
            </li>
        </ul>
        <div class="save_desk_img">
            <img :src="img"/>
        </div>
        <div class="go_home">
            <button @click="goHome">点击选择彩种</button>
        </div>
    </div>
</template>
<script lang='ts'>
import { computed, reactive, toRefs } from 'vue'
import { jumpTo } from '../../utils/util'
export default {
    name: '',
    setup() {
         const state= reactive({
             list: [
                 {label: '苹果手机', img: require('../../assets/imgs/save-desk/iphone.png')},
                 {label: '华为手机', img: require('../../assets/imgs/save-desk/huawei.png')},
                 {label: '小米手机', img: require('../../assets/imgs/save-desk/mi.png')},
                 {label: '其它', img: require('../../assets/imgs/save-desk/other.png')}
             ],
             currentIndex: 0
        })
        const titles = computed(() => {
            return state.list.map(ele => ele.label)
        })
        const img = computed(() => {
            return state.list[state.currentIndex].img
        })
        function goHome() {
            jumpTo('/')
        }
        return {
            ...toRefs(state),
            titles,
            img,
            goHome
        }
    },
}
</script>
<style lang='scss' scoped>
.save_desk {
    padding: $margin-big*2;
    padding-bottom: $margin-big*3.5;
    &_title {
        h1 {
            font-size: $font-big;
        }
        p {
            color: $gray-light;
            padding: 3px 0;
        }
    }
    &_items {
        display: flex;
        margin: 20px 0;
        .active {
            color: #fff;
            background: $theme-dark;
        }
        li {
            width: 25%;
            text-align: center;
            border-radius: $box-radius-small;
            background: #ebebeb;
            padding: 7px 0;
            color: $gray-middle;
        }
    }
    &_img {
        img {
            max-width: 100%;
        }
    }
    .go_home {
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        // height: 60px;
        padding: 10px 0;
        background: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-top: $line-gray solid 1px;
        button {
            background: $theme-dark;
            color: #fff;
            font-size: $font-big;
            width: 75%;
            padding: 10px 0;
            border-radius: $box-radius-small;
        }
    }
}
</style>